angular.module('vdmApp')
.controller('AgendaitemEditChecklistCtrl', function ($scope, $log, $timeout, $uibModalInstance, $uibModal, Checklist, data) {
    $scope.errors = [];
    $scope.messages = [];
    $scope.isEdit = true;

    $scope.event = {
      id: data.id,
      agendaitem_id: data.agendaitem_id,
      fee: data.fee,
      workdays: data.workdays,
      buyout: data.buyout,
      buyout_description: data.buyout_description,
      inclusive_af: data.inclusive_af,
      inclusive_af_description: data.inclusive_af_description,
      fee_model: data.fee_model,
      flights: data.flights,
      flights_description: data.flights_description,
      stayings: data.stayings,
      stayings_description: data.stayings_description,
      overtime: data.overtime,
      overtime_description: data.overtime_description,
      travel_expenses: data.travel_expenses,
      travel_expenses_description: data.travel_expenses_description,
      callsheet_po: data.callsheet_po,
      callsheet_po_description: data.callsheet_po_description,
      other: data.other,
      other_description: data.other_description,
      test_shoot: data.test_shoot,
      test_shoot_description: data.test_shoot_description,
      model_statement: data.model_statement,
      model_statement_description: data.model_statement_description,
      model_commision_percent: data.model_commision_percent,
      model_commision_payment: data.model_commision_payment,        
      agency_fee_percent: data.agency_fee_percent,
      agency_fee_payment: data.agency_fee_payment,
      foreign_model: data.foreign_model,
      foreign_model_description: data.foreign_model_description,        
      mac_commision: data.mac_commision,
      mac_commision_description: data.mac_commision_description,
      vat_client: data.vat_client,   
    };


    $scope.ok = function () {
        $scope.errors = [];
        $scope.messages = [];

        Checklist.update($scope.event)
        .success(function(res){
          if(res.meta.success){
            $scope.messages.push(res.meta.message);
            // return Success to the Controller
            // indicating that we can refetch the events
            $uibModalInstance.close({success:true, data: $scope.event});
          } else {
            if(Object.keys(res.meta.message).length > 0){
              for (var i = res.meta.message.length - 1; i >= 0; i--) {
                $scope.errors.push(res.meta.message[i]);
              }
            } else {
              $scope.errors.push(res.meta.message);
            }
            $('.modal.fade.in').animate({scrollTop:0},0);
          }
        })
        .error(function(err){
          $log.debug('Error adding event', err);
          if(err.errors) {
            for(var key in err.errors) {
              if(err.errors.hasOwnProperty(key)){
                $scope.errors.push(err.errors[key]);
                $('.modal.fade.in').animate({scrollTop:0},0);
              }
            }
          }
        });
    };

    $scope.cancel = function () {
        if($scope.event && Object.keys($scope.event).length > 0){
            swal({
                title: "Are you sure?",
                text: "You have some unsaved changes!",
                type: "info",
                showCancelButton: true,
                confirmButtonClass: "btn-danger",
                confirmButtonText: "Yes",
                closeOnConfirm: true,
                closeOnCancel:true
            },
            function(isConfirm){
                if(isConfirm){
                    $uibModalInstance.dismiss('cancel');
                }
            });
        } else {
            $uibModalInstance.dismiss('cancel');
        }
    };
})