angular.module('vdmApp')
.controller('CalendarController', function($scope, $log, $compile, Client, Type, Model, Agency, Agenda,$uibModal, uiCalendarConfig) {
  var start = new Date();
  var end = new Date();

  $scope.selectedModels = [];
  $scope.models = [];
  $scope.modelsFiltered = [];
  $scope.clients = [];
  $scope.agencies = [];
  $scope.types = [];
  $scope.loading = false;

  $scope.modelTypes = [];
  $scope.availableModelTypes = [
    'Mainboard',
    'Direct',
    'Curve',
    'Development',
    'Academy',
    'To Follow',
    'Men',
    'International',
    'National',
  ];

  $scope.bookingtypes = [];
  $scope.availableBookingtypes = [
    'direct',
    'spain direct',
    'indirect',
    'women direct',
  ];

  $scope.agendatypes = [];
  $scope.availableAgendatypes = [];

  $scope.fetchedFiltered = function () {
    $scope.selectedModels = $scope.searchModels;
    fetchEvents(start, end);
  };

  $scope.applyModelFilters = function () {
    if ($scope.modelTypes.length) {
      $scope.selectedModels = $scope.models.filter(m => {
        return !!$scope.modelTypes.find(mt => mt === m.type);
      });
    }
  };

  $scope.applyAgendaItemFilters = function (agendaitems) {
    let result = agendaitems;

    if ($scope.bookingtypes.length) {
      result = result.filter(a => {
        return !!$scope.bookingtypes.find(bt => bt === a.bookingtype);
      });
    }

    if ($scope.agendatypes.length) {
      result = result.filter(a => {
        return !!$scope.agendatypes.find(at => at === a.type_id);
      });
    }

    return result;
  };

  $scope.clearFilters = function () {
    $scope.bookingtypes = [];
    $scope.agendatypes = [];
    $scope.modelTypes = [];
    $scope.modelsFiltered = $scope.models;
    fetchEvents(start, end);
  };

  $scope.filterOnModel = function (item) {
    $scope.modelTypes = $scope.modelTypes.find(x => x === item)
    ? $scope.modelTypes.filter(x => x !== item)
    : $scope.modelTypes.concat(item);

    if ($scope.modelTypes.length) {
       $scope.modelsFiltered = $scope.models.filter(m => {
        return !!$scope.modelTypes.find(mt => mt === m.type);
      });
    }

    fetchEvents(start, end);
  };

  $scope.filterOnBookingtype = function (item) {
    $scope.bookingtypes = $scope.bookingtypes.find(x => x === item)
    ? $scope.bookingtypes.filter(x => x !== item)
    : $scope.bookingtypes.concat(item);

    fetchEvents(start, end);
  };

  $scope.filterOnAgendatype = function (item) {
    $scope.agendatypes = $scope.agendatypes.find(x => x === item)
    ? $scope.agendatypes.filter(x => x !== item)
    : $scope.agendatypes.concat(item);

    fetchEvents(start, end);
  };

  $scope.isTypeSelected = function (id) {
    return $scope.agendatypes.find(x => x.id === id);
  };

  $scope.getTypeById = function (id) {
    return $scope.types.find(x => x.id === id);
  };

  $scope.clearModelTypeFilters = function () {
    $scope.modelTypes = [];
  };

  /* Initialize models */
  Model.getModels().success(function(data){
    $scope.models = data.data;
    $scope.modelsFiltered = $scope.models;
  }).error(function(data){
    $log.error(data);
  });

  /* Initialize Clients */
  Client.getClients().success(function(data){
    $scope.clients = data.data;
  }).error(function(data){
    $log.error(data);
  });

  /* Initialize agenda items */
  Agency.getAgencies().success(function(data){
    $scope.agencies = data.data;
  }).error(function(data){
    $log.error(data);
  });

  /* Initialize types */
  Type.getTypes().success(function(data){
    $scope.types = data.data;
    $scope.availableAgendatypes = data.data.map(x => x.name);
  }).error(function(data){
    $log.error(data);
  });

  $scope.toggleModel = function(model){
    if($scope.isModelSelected(model)){
      var index = $scope.selectedModels.indexOf(model);
      $scope.selectedModels.splice(index, 1);
    } else {
      // Adding new model, so add new EventSource
      $scope.selectedModels.push(model);
    }
    fetchEvents(start, end)
  }

  $scope.selectAll = function(){
    $scope.clearModelTypeFilters();

    for (var i = $scope.models.length - 1; i >= 0; i--) {
      if(!$scope.isModelSelected($scope.models[i])){
        $scope.selectedModels.push($scope.models[i]);
      }
    }
    fetchEvents(start, end);
  }

  $scope.deselectAll = function() {
    $scope.clearModelTypeFilters();
    $scope.selectedModels = [];
    $scope.removeEvents();
  }

  $scope.isModelSelected = function(model){
    for (var i = $scope.selectedModels.length - 1; i >= 0; i--) {
      if(model.id == $scope.selectedModels[i].id){
        return true;
      }
    }
    return false;
  }

  /* alert on eventClick */
  $scope.onEventClick = function(data, jsEvent, view){
    var modalInstance = $uibModal.open({
      animation: true,
      templateUrl: '/js/views/AgendaEditItem.html',
      controller: 'AgendaEditItemCtrl',
      size: 'lg',
      resolve: {
        data: data,
        models: function(){
          return $scope.models;
        },
        clients: function(){
          return $scope.clients;
        },
        agencies: function(){
          return $scope.agencies;
        },
        types: function() {
          return $scope.types;
        }
      }
    });

    modalInstance.result.then(function (result) {
      if(result && result.success){
        fetchEvents(start, end);
        /* Initialize Clients */
        Client.getClients().success(function(data){
          $scope.clients = data.data;
        }).error(function(data){
          $log.error(data);
        });

        /* Initialize agenda items */
        Agency.getAgencies().success(function(data){
          $scope.agencies = data.data;
        }).error(function(data){
          $log.error(data);
        });}
      }, function () {

    });
  };

  /* alert on Drop */
  $scope.alertOnDrop = function(event, delta, revertFunc, jsEvent, ui, view){
    Agenda.updateItem(event)
    .success(function(res){
       fetchEvents(view.start, view.end);
    })
    .error(function(err){
      $log.error(err);
      revertFunc();
    });
  };
  /* alert on Resize */
  $scope.alertOnResize = function(event, delta, revertFunc, jsEvent, ui, view ){
    Agenda.updateItem(event)
    .success(function(res){
       fetchEvents(view.start, view.end);
    })
    .error(function(err){
      $log.error(err);
      revertFunc();
    });
  };

  $scope.onDayClick = function(date){
    $log.info("Day clicked : " + date);
    if(typeof date == 'undefined'){
      date = moment();
    } else {
      // the moment generated from fullcalendar is a different moment than the locally imported momentjs
      date = moment.unix(date.unix());
    }

    var modalInstance = $uibModal.open({
      animation: true,
      templateUrl: '/js/views/AgendaAddItem.html',
      controller: 'AgendaAddItemCtrl',
      size: 'lg',
      resolve: {
        data: date,
        models: function(){
          return $scope.models;
        },
        clients: function(){
          return $scope.clients;
        },
        agencies: function(){
          return $scope.agencies;
        },
        types: function() {
          return $scope.types;
        }
      }
    });

    modalInstance.result.then(function (result) {
      // Result true indicates a new Event was added and we can refetch
      if(result && result.success){
        fetchEvents(start, end);
      }
      Client.getClients().success(function(data){
        $scope.clients = data.data;
      }).error(function(data){
        $log.error(data);
      });
      /* Initialize agenda items */
      Agency.getAgencies().success(function(data){
        $scope.agencies = data.data;
      }).error(function(data){
        $log.error(data);
      });
    }, function () {
      // $log.info('Modal dismissed at: ' + new Date());
    });
  };

  /* add custom event*/
  $scope.addEvent = function(eventData) {
    /* start / end time * 1000 in Backend
    because javascript uses other timestamps */
    var classNames = [];

    if (eventData.status)
      classNames.push(eventData.status.toLowerCase());
    if(eventData.type == 'Job'){
      classNames.push('boldEvent');
    }
    if(eventData.bookingtype){
      classNames.push(eventData.bookingtype);
    }

    var event = {
      id: eventData.id,
      title: eventData.model_name + " | " + eventData.name,
      name: eventData.name,
      description: eventData.description,
      start: eventData.start,
      end: moment(eventData.end).endOf('day'),
      model: eventData.model,
      model_name: eventData.model_name,
      request_date: eventData.request_date,
      client: eventData.client,
      agency: eventData.agency,
      type_id: eventData.type_id,
      type: eventData.type,
      fee: eventData.fee,
      files: eventData.files,
      checklist: eventData.checklist,
      invoices: eventData.invoices,
      mc: eventData.mc,
      cc: eventData.cc,
      mac: eventData.mac,
      castingDirector: eventData.casting_director,
      location: eventData.location,
      stylist: eventData.stylist,
      bookinginfo: eventData.bookinginfo,
      photographer: eventData.photographer,
      color: eventData.color,
      status: eventData.status,
      booker: eventData.booker,
      contactperson: eventData.contactperson,
      bookingtype: eventData.bookingtype,
      notes: eventData.notes,
      textColor: eventData.textColor,
      className: classNames,
    }

    return event;
  };

  $scope.removeEvents = function(){
    if(uiCalendarConfig.calendars['myCalendar']){
      uiCalendarConfig.calendars['myCalendar'].fullCalendar('removeEvents', function(event){
        return true;
      });
    }
  };

  var fetchEvents = function(start, end, timezone, callback){
    $scope.loading = true;

    $scope.applyModelFilters();

    // only send the model id's
    var models = $scope.selectedModels.map(function(model){
      return model.id;
    });
    //  start / 1000 because of javascript timestamp vs database
    Agenda.getModelsItems(models, start/1000, end/1000)
    .success(function(data){
      if(!data.meta.success){
        $scope.loading = false;
        return;
      }

      const filteredData = $scope.applyAgendaItemFilters(data.data);
      var events = [];
      var ids = $scope.selectedModels.map(function(model){
        return parseInt(model.id);
      });
      for (var i = 0; i <filteredData.length; i++) {
        // Extra check if the model is selected
        if(ids.indexOf(parseInt(filteredData[i].model)) !== -1){
          events.push($scope.addEvent(filteredData[i]));
        }
      }
      $scope.removeEvents();

      if(callback){
        $scope.removeEventSources();
        callback(events);
      } else {
        $scope.removeAndAddEvents(events);
      }
      $scope.loading = false;
    })
    .error(function(err){
      $log.error(err);
      $scope.loading = false;
    });
  }

  /**
   * FullCalendar specific
   */
  /* Change View */
  $scope.changeView = function(view,calendar) {
    uiCalendarConfig.calendars[calendar].fullCalendar('changeView',view);
    fetchEvents(start, end);
  };
  $scope.removeEventSources = function() {
    if(uiCalendarConfig.calendars['myCalendar']){
      uiCalendarConfig.calendars['myCalendar'].fullCalendar('removeEventSources');
    }
  }

  /* add and removes an event source of choice */
  $scope.removeAndAddEvents = function(newEvents) {
    if(uiCalendarConfig.calendars['myCalendar']){
      uiCalendarConfig.calendars['myCalendar'].fullCalendar('removeEventSources');
      uiCalendarConfig.calendars['myCalendar'].fullCalendar('addEventSource', newEvents);
    }
  };

  $scope.rerenderEvents = function(){
    if(uiCalendarConfig.calendars['myCalendar']){
      uiCalendarConfig.calendars['myCalendar'].fullCalendar('rerenderEvents');
    }
  };

  /* Change View */
  $scope.renderCalender = function(calendar) {
    if(uiCalendarConfig.calendars[calendar]){
      uiCalendarConfig.calendars[calendar].fullCalendar('render');
    }
  };

  var getSelectedModelIds = function(){
    return $scope.selectedModels.map(function(model){
      return model.id
    });
  }

  $scope.uiConfig = {
    calendar: {
      displayEventTime: false,
      events: fetchEvents,
      height: "100%",
      editable: true,
      loading: function(isLoading, view){
        $scope.loading = isLoading;
      },
      header:{
        left: 'title',
        center: '',
        right: 'today prev,next'
      },
      dayClick: $scope.onDayClick,
      eventClick: $scope.onEventClick,
      eventDrop: $scope.alertOnDrop,
      eventResize: $scope.alertOnResize,
      defaultTimedEventDuration: '23:59:00',
      timezone: "local",
      timeFormat: ' ',
      ignoreTimezone: false,
      weekNumbers: true,
      firstDay: 1,
      weekNumberCalculation:"ISO",
      viewRender: function(view, element){
        start = view.start;
        end = view.end;
        fetchEvents(start, end);
      }
    }
  };
  /* event sources array*/
  $scope.eventSources = [];

});
