angular.module('vdmApp')
  .controller('ClientController', function($scope, $log, $compile, $uibModal) {

  $scope.onUploadClick = function() {
    var modalInstance = $uibModal.open({
      animation: true,
      templateUrl: '/js/views/AddFiles.html',
      controller: 'ClientAddFileController',
      size: 'lg',
      resolve: {}
    });
  };
});
