angular.module('vdmApp')
.controller('ProposalsAgendaAddItemCtrl', function ($scope, Upload, Print, $log, $timeout, $uibModalInstance, $uibModal, ProposalsAgenda, Client, Agency, data, clients, agencies, statusses) {
    $scope.data = data;
    $scope.clients = clients;
    $scope.statusses = statusses;
    $scope.errors = [];
    $scope.messages = [];
    $scope.files = [];
    $scope.invalidFiles = null;
    $scope.errorMsg = '';
    $scope.maxUploadSize = window.upload_max_filesize + 'B';

    $scope.item = {
      selected: false
    };


  $scope.event = {
        name: null,
        client_id: null,
        request_date: $scope.data,
        booker: null,
        contactperson: null,
        description: null,
        models_proposed: null,
        models_requested: null,
        photographer: null,
        stylist: null,
        casting_director: null,
        agency: null,
        status_id: null,
        type: null,
        clienttype: null,
        files: [],
    };

    $scope.types = [
      'Proposal Request',
      'VDM Acquisition',
      'Test',
    ];

    $scope.clienttypes = [
      'Regular',
      'New',
    ];

    $scope.printEvent = function () {
        return Print.proposalAgendaitem($scope.event, $scope.files, $scope.clients, $scope.agencies, $scope.statusses);
    };

  $scope.onSelected = function (selectedItem) {
    $scope.item.selected = !!+clients.find(client => client.id === selectedItem).address_book;
  };

    $scope.mailEvent = function () {
      var modalInstance = $uibModal.open({
        animation: true,
        templateUrl: '/js/views/ProposalEmail.html',
        controller: 'EmailController',
        size: 'lg',
      });

      modalInstance.result.then(function (result) {
        const receiver = result.email;
        if (!receiver) {
          $scope.errors.push('No email given!');
          $('.modal.fade.in').animate({scrollTop:0},0);
          return;
        }

        return ProposalsAgenda.mail($scope.event, receiver)
          .success(() => {
            $scope.messages.push('Mail has been sent!');
            $('.modal.fade.in').animate({scrollTop:0},0);
          })
          .error((err) => {
            $scope.errors.push('Server error mail has not been sent!');
            $('.modal.fade.in').animate({scrollTop:0},0);
          });
      });
    };

    $scope.uploadFiles = function (files) {
        $scope.files = $scope.files.concat(files);
        if (files && files.length) {
            Upload.upload({
                url: '/api/file/store',
                data: {
                    files: files
                }
            }).then(function (response) {
                $timeout(function () {
                    $scope.files.forEach((x, i) => {
                        const item = response.data.find(y => y.name === x.name);
                        if (item) {
                            $scope.files[i].id = item.id;
                            $scope.files[i].file = item.file;
                        }
                    });

                    $scope.event.files = $scope.files.map(x => x.id);
                });
            }, function (response) {
                if (response.status > 0) {
                    $scope.errorMsg = response.status + ': ' + response.data;
                }
            }, function (evt) {
                $scope.progress = 
                    Math.min(100, parseInt(100.0 * evt.loaded / evt.total));
            });
        }
    };

    $scope.removeFile = function (file) {
      swal({
        title: "Are you sure?",
        text: "The file will be deleted!",
        type: "info",
        showCancelButton: true,
        confirmButtonClass: "btn-danger",
        confirmButtonText: "Yes",
        closeOnConfirm: true,
        closeOnCancel:true
      },
      function(isConfirm){
        if(isConfirm){
          $scope.files = $scope.files.filter(x => x.name !== file.name);
          if (file.id > -1) {
              $scope.event.files = $scope.event.files.filter(x => x !== file.id);
          }
        }
        $scope.$apply();
      });
    }

    $scope.ok = function () {
        $scope.errors = [];
        $scope.messages = [];

      Client.setAddressBook($scope.event.client_id, $scope.item.selected ? 1 : 0);

        ProposalsAgenda.add($scope.event)
        .success(function(res){
            $log.debug(res);
            if(res.meta.success){
                $scope.messages.push(res.meta.message);
                $uibModalInstance.close({success:true});
            } else {
                $scope.errors.push(res.meta.message);
            }
        })
        .error(function(err){
            $log.debug('Error adding event', err);
            if(err.errors) {
                for(var key in err.errors) {
                    if(err.errors.hasOwnProperty(key)){
                        $scope.errors.push(err.errors[key]);
                    }
                }
            }
        });
    };

    $scope.cancel = function () {
        if($scope.event && Object.keys($scope.event).length > 0){
            swal({
                title: "Are you sure?",
                text: "You have some unsaved changes!",
                type: "info",
                showCancelButton: true,
                confirmButtonClass: "btn-danger",
                confirmButtonText: "Yes",
                closeOnConfirm: true,
                closeOnCancel:true
            },
            function(isConfirm){
                if(isConfirm){
                    $uibModalInstance.dismiss('cancel');
                }
            });
        } else {
            $uibModalInstance.dismiss('cancel');
        }
    };

    $scope.addAgency = function() {
      $log.info('addAgencty clicked');
      var modalInstance = $uibModal.open({
        animation: true,
        templateUrl: '/js/views/AddAgency.html',
        controller: 'AddAgencyController',
        size: 'lg',
        resolve: {
          data: data,
        }
      });
      modalInstance.result.then(function (result) {
        if(result && result.success){
          Agency.getAgencies().success(function(data){
            $scope.agencies = data.data;
          }).error(function(data){
            $log.error(data);
          });
        }
      }, function (err) {
        $log.error(err);
      });
    };

    $scope.addClient = function() {
      $log.info('addClient clicked');
      var modalInstance = $uibModal.open({
        animation: true,
        templateUrl: '/js/views/AddClient.html',
        controller: 'AddClientController',
        size: 'lg',
        resolve: {
          data: data,
        }
      });
      modalInstance.result.then(function (result) {
        if(result && result.success){
          Client.getClients().success(function(data){
            $scope.clients = data.data;
          }).error(function(data){
            $log.error(data);
          });
        }
      }, function (err) {
        $log.error(err);
      });
    };
})
