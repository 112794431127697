angular.module('vdmApp')
.factory('Agenda', function($http) {
  var transform = function(event, receiver){
    var transformedEvent = {
        name: event.name,
        model : event.model,
        description: event.description,
        start: event.start.startOf('day').unix(),
        end: event.end.endOf('day').unix(),
        type: event.type_id,
        status: event.status,
        request_date: event.request_date ? event.request_date.unix() : null,
        client: event.client,
        agency: event.agency,
        fee: event.fee,
        mc: event.mc,
        cc: event.cc,
        mac: event.mac,
        castingDirector: event.castingDirector,
        location: event.location,
        stylist: event.stylist,
        bookinginfo: event.bookinginfo,
        photographer: event.photographer,
        bookingtype: event.bookingtype,
        booker: event.booker,
        contactperson: event.contactperson,
        notes: event.notes,
        files: event.files,
        checklist: event.checklist,
        invoices: event.invoices,
        invoicesIds: event.invoices.map(x => x.id),
    };
    if (receiver) {
      transformedEvent.receiver = receiver;
    }
    return transformedEvent;
  };

  return {
    getAllItems: function(){
      return $http.get('/api/agenda');
    },
    getItem: function(id) {
      return $http.get(`/api/agenda/${id}`);
    },
    mailItem: function(event, receiver) {
      return $http.post('/api/agenda/mail', transform(event, receiver));
    },
    getModelsItems: function(models, start, end){
      return $http.post('/api/agenda/models', {
        start: start,
        end: end,
        models:models
      });
    },
    addItem: function(event){
      return $http.post('/api/agenda/store', transform(event));
    },
    duplicateItem: function(event) {
      return $http.post('/api/agenda/' + event.id + '/duplicate');
    },
    updateItem: function(event) {
      return $http.post('/api/agenda/'+event.id+'/update', transform(event));
    },
    removeItem: function(eventId) {
      return $http.delete('/api/agenda/'+eventId+'/removeItem');
    }
  }
  })
.factory('ProposalsAgenda', function($http) {
    return {
      get: function(id){
        if (!id) return $http.get('/api/proposals');
        return $http.get(`/api/proposals/${id}`);
      },
      getClientsItems: function(clients, start, end){
        return $http.post('/api/proposals/clients', {
          start: start,
          end: end,
          clients:clients
        });
      },
      mail: function (event, receiver) {
        return $http.post('/api/proposals/mail', Object.assign({}, event, { receiver }));
      },
      add: function(proposal){
        var res = Object.assign({}, proposal);
        if (res.request_date) res.request_date = res.request_date.unix();
        return $http.post('/api/proposals', res);
      },
      update: function(proposal){
        var res = Object.assign({}, proposal);
        if (res.request_date) res.request_date = moment(res.request_date).unix();
        return $http.put(`/api/proposals/${proposal.id}`, res);
      },
      remove: function(id){
        return $http.delete(`/api/proposals/${id}`);
      },
    };
})
.factory('Checklist', function($http) {
  return {
    get: function(id){
      return $http.get(`/api/checklist/${id}`);
    },
    add: function(checklist){
      var res = Object.assign({}, checklist);
      return $http.post('/api/checklist', res);
    },
    update: function(checklist){
      var res = Object.assign({}, checklist);
      return $http.put(`/api/checklist/${checklist.id}`, res);
    },
  }
})
.factory('Invoice', function($http) {
    return {
      get: function(id){
        return $http.get(`/api/invoice/${id}`);
      },
      add: function(invoice){
        var res = Object.assign({}, invoice);
        if (res.send_date) res.send_date = res.send_date.unix();
        res.start = res.start.unix();
        res.end = res.end.unix();
        return $http.post('/api/invoice', res);
      },
      update: function(invoice){
        var res = Object.assign({}, invoice);
        if (res.send_date) res.send_date = res.send_date.unix();
        res.start = res.start.unix();
        res.end = res.end.unix();
        return $http.put(`/api/invoice/${invoice.id}`, res);
      },
      remove: function(invoice){
        return $http.delete(`/api/invoice/${invoice}`);
      },
      generate: function(id) {
        return $http.get(`/api/invoicepdf/${id}`);
      },
      getLang: function(lang) {
        return $http.get(`/api/invoicelang?lang=${lang}`);
      },
    }
})
.factory('Model', function($http) {
    return {
      getModels: function(){
        return $http.get('/api/model');
      }
    }
  })
.factory('Type', function($http) {
  return {
    getTypes: function(){
      return $http.get('/api/type');
    }
  }
})
.factory('ProposalsStatus', function($http) {
  return {
    getStatusses: function(){
      return $http.get('/api/proposalsstatus');
    }
  }
})
.factory('Client', function($http) {
  return {
    getClients: function() {
      return $http.get('/api/client');
    },
    store: function(client) {
      return $http.post('/api/client/store', client);
    },
    storeFiles: function(id, files) {
      return $http.post(`/api/client/storefiles/${id}`, {files: files});
    },
    setAddressBook: function(id, addressBook) {
      return $http.post(`api/client/${id}/updateAddress`, {address_book: addressBook});
    }
  }
})
.factory('Agency', function($http) {
  return {
    getAgencies: function() {
      return $http.get('/api/agency');
    },
    store: function(agency) {
      return $http.post('/api/agency/store', agency);
    }
  }
})
.factory('Group', function($http) {
  return {
    getGroups: function() {
      return $http.get('/api/group');
    }
  }
})
.factory('Country', function($http) {
  return {
    getCountries: function() {
      return $http.get('/api/country');
    }
  }
})
.factory('Print', function() {
  return {
    agendaitem: function(input, files, models, clients, agencies, types) {
      let WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
      WinPrint.document.write(renderPrint(input, files, models, clients, agencies, types));
      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      WinPrint.close();
    },
    proposalAgendaitem: function(input, files, clients, agencies, statusses) {
      let WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
      WinPrint.document.write(renderProposalPrint(input, files, clients, agencies, statusses));
      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      WinPrint.close();
    },
  }
});

function renderProposalPrint(data, files, clients, agencies, statusses) {
  const input = Object.assign({}, data);

  let x = {};
  Object.keys(input).forEach(key => {
    if (input[key] !== 0 && !input[key]) {
      x[key] = '';
    } else {
      x[key] = input[key];
    }
  });

  if (Number.isInteger(x.client_id)) {
    x.client = clients.find(y => y.id === x.client_id).name;
  }

  const status_id = parseInt(x.status_id);
  if (Number.isInteger(status_id)) {
    x.status = statusses.find(y => y.id === status_id).name;
  }

  x.request_date = moment(x.request_date);

  return `
    <h1>Event</h1>
    <br />
    <table>
      <tr style="border-bottom: 1px solid black;">
        <td style="padding: 6px;">name</td><td style="padding: 6px;"><b>${x.name}</b></td>
      </tr>
      <tr style="border-bottom: 1px solid black;">
        <td style="padding: 6px;">client</td><td style="padding: 6px;"><b>${x.client}</b></td>
      </tr>
      <tr style="border-bottom: 1px solid black;">
        <td style="padding: 6px;">request date</td><td style="padding: 6px;"><b>${moment.isMoment(x.request_date) ? x.request_date.format('DD MM YYYY') : ''}</b></td>
      </tr>
      <tr style="border-bottom: 1px solid black;">
        <td style="padding: 6px;">booker</td><td style="padding: 6px;"><b>${x.booker}</b></td>
      </tr>
      <tr style="border-bottom: 1px solid black;">
        <td style="padding: 6px;">contactperson</td><td style="padding: 6px;"><b>${x.contactperson}</b></td>
      </tr>
      <tr style="border-bottom: 1px solid black;">
        <td style="padding: 6px;">description</td><td style="padding: 6px;"><b>${x.description}</b></td>
      </tr>
      <tr style="border-bottom: 1px solid black;">
        <td style="padding: 6px;">models proposed</td><td style="padding: 6px;"><b>${x.models_proposed}</b></td>
      </tr>
      <tr style="border-bottom: 1px solid black;">
        <td style="padding: 6px;">models requested</td><td style="padding: 6px;"><b>${x.models_requested}</b></td>
      </tr>
      <tr style="border-bottom: 1px solid black;">
        <td style="padding: 6px;">photographer</td><td style="padding: 6px;"><b>${x.photographer}</b></td>
      </tr>
      <tr style="border-bottom: 1px solid black;">
        <td style="padding: 6px;">casting director</td><td style="padding: 6px;"><b>${x.casting_director}</b></td>
      </tr>
      <tr style="border-bottom: 1px solid black;">
        <td style="padding: 6px;">agency</td><td style="padding: 6px;"><b>${x.agency}</b></td>
      </tr>
      <tr style="border-bottom: 1px solid black;">
        <td style="padding: 6px;">status</td><td style="padding: 6px;"><b>${x.status}</b></td>
      </tr>
      <tr style="border-bottom: 1px solid black;">
        <td style="padding: 6px;">type</td><td style="padding: 6px;"><b>${x.type}</b></td>
      </tr>
      <tr style="border-bottom: 1px solid black;">
        <td style="padding: 6px;">clienttype</td><td style="padding: 6px;"><b>${x.clienttype}</b></td>
      </tr>
      <tr style="border-bottom: 1px solid black;">
        <td style="padding: 6px;">files</td>
        <td style="padding: 6px;">${renderFiles(files)}</td>
      </tr>
    </table>
  `;
}

function renderPrint(data, files, models, clients, agencies, types) {
  const input = Object.assign({}, data);

  let x = {};
  Object.keys(input).forEach(key => {
    if (input[key] !== 0 && !input[key]) {
      x[key] = '';
    } else {
      x[key] = input[key];
    }
  });

  if (Array.isArray(x.model)) {
    x.model = x.model.map(x => models.find(y => x === y.id).name).join(', ');
  }
  if (Number.isInteger(x.model)) {
    x.model = models.find(y => y.id === x.model).name;
  }

  if (Number.isInteger(x.client)) {
    x.client = clients.find(y => y.id === x.client).name;
  }

  if (Number.isInteger(x.agency)) {
    x.agency = agencies.find(y => y.id === x.agency).name;
  }

  const typeId = parseInt(x.type_id);
  if (Number.isInteger(typeId)) {
    x.type = types.find(y => y.id === typeId).name;
  }

  return `
    <h1>Event</h1>
    <br />
    <table>
      <tr style="border-bottom: 1px solid black;">
        <td style="padding: 6px;">name</td><td style="padding: 6px;"><b>${x.name}</b></td>
      </tr>
      <tr style="border-bottom: 1px solid black;">
        <td style="padding: 6px;">description</td><td style="padding: 6px;"><b>${x.description}</b></td>
      </tr>
      <tr style="border-bottom: 1px solid black;">
        <td style="padding: 6px;">model</td><td style="padding: 6px;"><b>${x.model}</b></td>
      </tr>
      <tr style="border-bottom: 1px solid black;">
        <td style="padding: 6px;">request date</td><td style="padding: 6px;"><b>${moment.isMoment(x.request_date) ? x.request_date.format('DD MM YYYY') : ''}</b></td>
      </tr>
      <tr style="border-bottom: 1px solid black;">
        <td style="padding: 6px;">client</td><td style="padding: 6px;"><b>${x.client}</b></td>
      </tr>
      <tr style="border-bottom: 1px solid black;">
        <td style="padding: 6px;">agency</td><td style="padding: 6px;"><b>${x.agency}</b></td>
      </tr>
      <tr style="border-bottom: 1px solid black;">
        <td style="padding: 6px;">fee</td><td style="padding: 6px;"><b>${x.fee}</b></td>
      </tr>
      <tr style="border-bottom: 1px solid black;">
        <td style="padding: 6px;">mc</td><td style="padding: 6px;"><b>${x.mc}</b></td>
      </tr>
      <tr style="border-bottom: 1px solid black;">
        <td style="padding: 6px;">cc</td><td style="padding: 6px;"><b>${x.cc}</b></td>
      </tr>
      <tr style="border-bottom: 1px solid black;">
        <td style="padding: 6px;">mac</td><td style="padding: 6px;"><b>${x.mac}</b></td>
      </tr>
      <tr style="border-bottom: 1px solid black;">
        <td style="padding: 6px;">casting director</td><td style="padding: 6px;"><b>${x.castingDirector}</b></td>
      </tr>
      <tr style="border-bottom: 1px solid black;">
        <td style="padding: 6px;">location</td><td style="padding: 6px;"><b>${x.location}</b></td>
      </tr>
      <tr style="border-bottom: 1px solid black;">
        <td style="padding: 6px;">stylist</td><td style="padding: 6px;"><b>${x.stylist}</b></td>
      </tr>
      <tr style="border-bottom: 1px solid black;">
        <td style="padding: 6px;">photographer</td><td style="padding: 6px;"><b>${x.photographer}</b></td>
      </tr>
      <tr style="border-bottom: 1px solid black;">
        <td style="padding: 6px;">type</td><td style="padding: 6px;"><b>${x.type}</b></td>
      </tr>
      <tr style="border-bottom: 1px solid black;">
        <td style="padding: 6px;">start</td><td style="padding: 6px;"><b>${moment.isMoment(x.start) ? x.start.format('DD MM YYYY') : ''}</b></td>
      </tr>
      <tr style="border-bottom: 1px solid black;">
        <td style="padding: 6px;">end</td><td style="padding: 6px;"><b>${moment.isMoment(x.end) ? x.end.format('DD MM YYYY') : ''}</b></td>
      </tr>
      <tr style="border-bottom: 1px solid black;">
        <td style="padding: 6px;">status</td><td style="padding: 6px;"><b>${x.status}</b></td>
      </tr>
      <tr style="border-bottom: 1px solid black;">
        <td style="padding: 6px;">bookingtype</td><td style="padding: 6px;"><b>${x.bookingtype}</b></td>
      </tr>
      <tr style="border-bottom: 1px solid black;">
        <td style="padding: 6px;">booker</td><td style="padding: 6px;"><b>${x.booker}</b></td>
      </tr>
      <tr style="border-bottom: 1px solid black;">
        <td style="padding: 6px;">contactperson</td><td style="padding: 6px;"><b>${x.contactperson}</b></td>
      </tr>
      <tr style="border-bottom: 1px solid black;">
        <td style="padding: 6px;">files</td>
        <td style="padding: 6px;">${renderFiles(files)}</td>
      </tr>
      <tr style="border-bottom: 1px solid black;">
        <td style="padding: 6px;">files</td>
        <td style="padding: 6px;">${renderInvoices(data.invoices)}</td>
      </tr>
    </table>
  `;
}

function renderFiles(files) {
  let result = '';
  for(let i = 0; i < files.length; i++) {
    result += `file: <b>${files[i].name}</b><br />url: <b>${location.protocol}//${location.host}/api/file/${files[i].id}</b><br /><br />`;
  }
  return result;
}

function renderInvoices(invoices) {
  let result = '';
  for(let i = 0; i < invoices.length; i++) {
    result += `English Invoice <b>${invoices[i].invoice_nr ? invoices[i].invoice_nr : ''}</b><br />url: <b>${location.protocol}//${location.host}/api/invoicepdf/${invoices[i].id}?lang=en</b><br /><br />`;
    result += `<br />Dutch Invoice <b>${invoices[i].invoice_nr ? invoices[i].invoice_nr : ''}</b><br />url: <b>${location.protocol}//${location.host}/api/invoicepdf/${invoices[i].id}?lang=nl</b><br /><br />`;
  }
  return result;
}
