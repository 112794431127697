angular.module('vdmApp')
  .controller('ClientAddFileController', function($scope, $uibModalInstance, $timeout, Upload, Client) {

    $scope.errors = [];
    $scope.messages = [];
    $scope.files = [];
    $scope.invalidFiles = null;
    $scope.errorMsg = '';
    $scope.maxUploadSize = window.upload_max_filesize + 'B';

    $scope.event = {
      files: []
    };

    $scope.cancel = function () {
      if($scope.event && Object.keys($scope.event).length > 0){
        swal({
            title: "Are you sure?",
            text: "You have some unsaved changes!",
            type: "info",
            showCancelButton: true,
            confirmButtonClass: "btn-danger",
            confirmButtonText: "Yes",
            closeOnConfirm: true,
            closeOnCancel:true
          },
          function(isConfirm){
            if(isConfirm){
              $uibModalInstance.dismiss('cancel');
            }
          });
      } else {
        $uibModalInstance.dismiss('cancel');
      }
    };

    $scope.uploadFiles = function (files) {
      $scope.files = $scope.files.concat(files);
      if (files && files.length) {
        Upload.upload({
          url: '/api/file/store',
          data: {
            files: files
          }
        }).then(function (response) {
          $timeout(function () {
            // kijk voor elke file als die bestaat in response
            // zoja, voeg de id toe aan de $scope.files
            $scope.files.forEach((x, i) => {
              const item = response.data.find(y => y.name === x.name);
              if (item) {
                $scope.files[i].id = item.id;
                $scope.files[i].file = item.file;
              }
            });

            $scope.event.files = $scope.files.map(x => x.id);
          });
        }, function (response) {
          if (response.status > 0) {
            $scope.errorMsg = response.status + ': ' + response.data;
          }
        }, function (evt) {
          $scope.progress = (100.0 * evt.loaded / evt.total);
        });
      }
    };

    $scope.removeFile = function (file) {
      swal({
          title: "Are you sure?",
          text: "The file will be deleted!",
          type: "info",
          showCancelButton: true,
          confirmButtonClass: "btn-danger",
          confirmButtonText: "Yes",
          closeOnConfirm: true,
          closeOnCancel:true
        },
        function(isConfirm){
          if(isConfirm){
            $scope.files = $scope.files.filter(x => x.name !== file.name);
            if (file.id > -1) {
              $scope.event.files = $scope.event.files.filter(x => x !== file.id);
            }
          }
          $scope.$apply();
        });
    };

    $scope.ok = function () {
      $scope.errors = [];
      $scope.messages = [];
      let clientId = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);
      Client.storeFiles(clientId, $scope.files).success(function(res){
        $uibModalInstance.dismiss('cancel');
        window.location.reload();
      });
    };

    /* event sources array*/
    $scope.eventSources = [];
});
