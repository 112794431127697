angular.module('vdmApp')
.controller('AgendaEditItemCtrl', function($scope, Upload, $timeout, $log, $uibModalInstance, $uibModal, Print, Client, Agency, Invoice, Checklist, Agenda, data, models, clients, agencies, types) {
  $scope.data = data;
  $scope.models = models;
  $scope.clients = clients;
  $scope.agencies = agencies;
  $scope.types = types;
  $scope.errors = [];
  $scope.messages = [];
  $scope.files = data.files;
  $scope.invalidFiles = null;
  $scope.errorMsg = '';
  $scope.maxUploadSize = window.upload_max_filesize + 'B';
  // hardcoded userId & email of jeroen (key user) for explicit content (MC, CC, MAC)
  $scope.isAdmin = user.email === 'jeroen@vdmmodels.com';

  $scope.item = {
      selected: false
  };


  $scope.statusses = [
      'Pending',
      'Confirmed by Client',
      'Confirmed by Model',
      'Cancelled by Client',
      'Cancelled by Model',
  ];

  $scope.bookingtypes = [
      'direct',
      'spain direct',
      'indirect',
      'women direct',
  ];

  $scope.event = {
    id: data.id,
    name: data.name,
    description: data.notes ? `${data.description}\r\n\r\n${data.notes}` : data.description,
    model: data.model,
    model_name: data.model_name ? data.model_name : 'Model is removed',
    request_date: moment(data.request_date),
    client: data.client,
    client_name: data.client_name,
    agency: data.agency,
    fee: data.fee,
    mc: data.mc,
    cc: data.cc,
    mac: data.mac,
    castingDirector: data.castingDirector,
    location: data.location,
    stylist: data.stylist,
    photographer: data.photographer,
    type: data.type,
    type_id: data.type_id,
    start: moment(data.start),
    end: moment(data.end),
    status: data.status,
    bookingtype: data.bookingtype,
    booker: data.booker,
    contactperson: data.contactperson,
    notes: data.notes,
    files: $scope.files.map(x => x.id),
    checklist: data.checklist,
    invoices: data.invoices,
  };

  $scope.getSelected = function() {
    if (clients){
      let client = clients.find(client => client.id === data.client);
      if (client) {
        return !!+client.address_book;
      }
    }
    return false;
  };

  $scope.item = {
    selected: $scope.getSelected(),
  };

  $scope.onSelected = function (selectedItem) {
    $scope.item.selected = !!+clients.find(client => client.id === selectedItem).address_book;
  };

  $scope.addInvoice = function () {
    // Validate agendaitem for invoice
    // check for client and model
    if (!$scope.event.client) {
      $scope.errors.push('You need to select a client before you can add an invoice');
      $('.modal.fade.in').animate({scrollTop:0},0);
      return;
    }

    if (!$scope.event.model) {
      $scope.errors.push('You need to select a model before you can add an invoice');
      $('.modal.fade.in').animate({scrollTop:0},0);
      return;
    }
    
    // Save AgendaItem before you can add invoice
    $scope.errors = [];
    $scope.messages = [];

    $scope.updateEvent()
    .success(function(res){
      if(res.meta.success){

        $scope.event.client_name = res.data[0].client_name;
        $scope.event.model_name = res.data[0].model_name;

        // Open add invoice modal
        var modalInstance = $uibModal.open({
          animation: true,
          templateUrl: '/js/views/AgendaitemAddInvoice.html',
          controller: 'AgendaitemAddInvoiceCtrl',
          size: 'lg',
          resolve: {
            data: $scope.event,
          },
        });

        modalInstance.result.then(function (result) {
          $scope.event.invoices.push(result.data);
        });
        
      } else {
        if(Object.keys(res.meta.message).length > 0){
          for (var i = res.meta.message.length - 1; i >= 0; i--) {
            $scope.errors.push(res.meta.message[i]);
          }
        } else {
          $scope.errors.push(res.meta.message);
        }
      }
    })
    .error(function(err){
      $log.debug('Error saving event', err);
      if(err.errors) {
        for(var key in err.errors) {
          if(err.errors.hasOwnProperty(key)){
            $scope.errors.push(err.errors[key]);
            $('.modal.fade.in').animate({scrollTop:0},0);
          }
        }
      }
    });    

  };

  $scope.openInvoice = function (invoice, i) {
    
    Invoice.get(invoice.id)
      .success(function (res) {
        var modalInstance = $uibModal.open({
          animation: true,
          templateUrl: '/js/views/AgendaitemAddInvoice.html',
          controller: 'AgendaitemEditInvoiceCtrl',
          size: 'lg',
          resolve: {
            data: res.data,
          },
        });

        modalInstance.result.then(function (result) {
          $scope.event.invoices[i] = result.data;
        });
      });
  };

  $scope.removeInvoice = function(invoice, index) {
    swal({
      title: "Are you sure?",
      text: "The invoice will be deleted!",
      type: "info",
      showCancelButton: true,
      confirmButtonClass: "btn-danger",
      confirmButtonText: "Yes",
      closeOnConfirm: true,
      closeOnCancel:true
    },
    function(isConfirm){
      if(isConfirm){
        if (invoice.id) {
          Invoice.remove(invoice.id); 
        }
        $scope.event.invoices = $scope.event.invoices.filter((x, i) => i !== index);
      }
    });
  };
  
  $scope.addChecklist = function () {
    // Validate agendaitem for invoice
    
    // Save AgendaItem before you can add invoice
    $scope.errors = [];
    $scope.messages = [];

    $scope.updateEvent()
    .success(function(res){
      if(res.meta.success){


        // Open add invoice modal
        var modalInstance = $uibModal.open({
          animation: true,
          templateUrl: '/js/views/AgendaitemEditChecklist.html',
          controller: 'AgendaitemAddChecklistCtrl',
          size: 'lg',
          resolve: {
            data: $scope.event,
          },
        });

        //maak van add button de edit button
        // modalInstance.result.then(function (result) {
        //   $scope.event.checklist.push(result.data);
        // });
        
      } else {
        if(Object.keys(res.meta.message).length > 0){
          for (var i = res.meta.message.length - 1; i >= 0; i--) {
            $scope.errors.push(res.meta.message[i]);
          }
        } else {
          $scope.errors.push(res.meta.message);
        }
      }
    })
    .error(function(err){
      $log.debug('Error saving event', err);
      if(err.errors) {
        for(var key in err.errors) {
          if(err.errors.hasOwnProperty(key)){
            $scope.errors.push(err.errors[key]);
            $('.modal.fade.in').animate({scrollTop:0},0);
          }
        }
      }
    });    

  };

  $scope.openChecklist = function (checklist) {
    
    Checklist.get(checklist.id)
      .success(function (res) {
        var modalInstance = $uibModal.open({
          animation: true,
          templateUrl: '/js/views/AgendaitemEditChecklist.html',
          controller: 'AgendaitemEditChecklistCtrl',
          size: 'lg',
          resolve: {
            data: res.data,
          },
        });

        modalInstance.result.then(function (result) {
          $scope.event.checklist = result.data;
        });
      });
  };

  $scope.printEvent = function () {
      return Print.agendaitem($scope.event, $scope.files, $scope.models, $scope.clients, $scope.agencies, $scope.types);
  };

  $scope.mailEvent = function () {
    var modalInstance = $uibModal.open({
      animation: true,
      templateUrl: '/js/views/Email.html',
      controller: 'EmailController',
      size: 'lg',
    });

    modalInstance.result.then(function (result) {
      const receiver = result.email;
      if (!receiver) {
        $scope.errors.push('No email given!');
        $('.modal.fade.in').animate({scrollTop:0},0);
        return;
      }

      return Agenda.mailItem($scope.event, receiver)
        .success(() => {
          $scope.messages.push('Mail has been sent!');
          $('.modal.fade.in').animate({scrollTop:0},0);
        })
        .error((err) => {
          $scope.errors.push('Server error mail has not been sent!');
          $('.modal.fade.in').animate({scrollTop:0},0);
        });
    });
  };

  $scope.uploadFiles = function (files) {
      $scope.files = $scope.files.concat(files);
      if (files && files.length) {
          Upload.upload({
              url: '/api/file/store',
              data: {
                  files: files
              }
          }).then(function (response) {
              $timeout(function () {
                  // kijk voor elke file als die bestaat in response
                  // zoja, voeg de id toe aan de $scope.files
                  $scope.files.forEach((x, i) => {
                      const item = response.data.find(y => y.name === x.name);
                      if (item) {
                          $scope.files[i].id = item.id;
                          $scope.files[i].file = item.file;
                      }
                  });

                  $scope.event.files = $scope.files.map(x => x.id);
              });
          }, function (err) {
            if (response.status > 0) {
              $scope.errorMsg = response.status + ': ' + response.data;
            }
          }, function (evt) {
              $scope.progress = 
                  Math.min(100, parseInt(100.0 * evt.loaded / evt.total));
          });
      }
  };

  $scope.removeFile = function (file) {
    swal({
      title: "Are you sure?",
      text: "The file will be deleted!",
      type: "info",
      showCancelButton: true,
      confirmButtonClass: "btn-danger",
      confirmButtonText: "Yes",
      closeOnConfirm: true,
      closeOnCancel:true
    },
    function(isConfirm){
      if(isConfirm){
        $scope.files = $scope.files.filter(x => x.name !== file.name);
        if (file.id > -1) {
            $scope.event.files = $scope.event.files.filter(x => x !== file.id);
        }
      }
      $scope.$apply();
    });
  }

  $scope.ok = function() {
    $scope.errors = [];
    $scope.messages = [];

    Client.setAddressBook($scope.event.client, $scope.item.selected ? 1 : 0);

    $scope.updateEvent()
    .success(function(res){
      if(res.meta.success){
        $scope.messages.push(res.meta.message);
        // return Success to the Controller
        // indicating that we can refetch the events
        $uibModalInstance.close({success:true});
      } else {
        if(Object.keys(res.meta.message).length > 0){
          for (var i = res.meta.message.length - 1; i >= 0; i--) {
            $scope.errors.push(res.meta.message[i]);
          }
        } else {
          $scope.errors.push(res.meta.message);
        }
        $('.modal.fade.in').animate({scrollTop:0},0);
      }
    })
    .error(function(err){
      $log.debug('Error adding event', err);
      if(err.errors) {
        for(var key in err.errors) {
          if(err.errors.hasOwnProperty(key)){
            $scope.errors.push(err.errors[key]);
            $('.modal.fade.in').animate({scrollTop:0},0);
          }
        }
      }
    });
  };

  $scope.cancel = function() {
    if($scope.event.name != data.name ||
      $scope.event.description != data.description ||
      $scope.event.model != data.model ||
      $scope.event.request_date != data.request_date ||
      $scope.event.client != data.client ||
      $scope.event.agency != data.agency ||
      $scope.event.fee != data.fee ||
      $scope.event.mc != data.mc ||
      $scope.event.cc != data.cc ||
      $scope.event.mac != data.mac ||
      $scope.event.castingDirector != data.castingDirector ||
      $scope.event.location != data.location ||
      $scope.event.stylist != data.stylist ||
      $scope.event.photographer != data.photographer ||
      $scope.event.type_id != data.type_id ||
      $scope.event.start != data.start ||
      $scope.event.end != data.end ||
      $scope.event.status != data.status ||
      $scope.event.bookingtype != data.bookingtype ||
      $scope.event.booker != data.booker||
      $scope.event.files != data.files||
      $scope.event.checklist != data.checklist||
      $scope.event.invoices != data.invoices||
      $scope.event.notes != data.notes)
    {
      swal({
        title: "Are you sure?",
        text: "You have some unsaved changes!",
        type: "info",
        showCancelButton: true,
        confirmButtonClass: "btn-success",
        confirmButtonText: "Yes I'm sure",
        closeOnConfirm: true,
        closeOnCancel:true
      },
      function(isConfirm){
        if(isConfirm){
          $uibModalInstance.dismiss('cancel');
        }
      });
    } else {
      $uibModalInstance.dismiss('cancel');
    }

  };

  $scope.duplicateEvent = function() {
    $scope.errors.length = 0;
    $scope.messages.length = 0;
    Agenda.duplicateItem($scope.event)
    .success(function(res) {
      if(res.meta.success){
        $scope.messages.push('Item has been duplicated');
        $uibModalInstance.close({success:true});
      }
    })
    .error(function(err) {
      $log.error(err);
    })
  }

  $scope.updateEvent = function(){
    $scope.event.start.startOf('day');
    $scope.event.end.endOf('day');
    const item = Object.assign({}, $scope.event);
    item.invoices = item.invoices.map(x => x.id);
    return Agenda.updateItem(item);
  }

  $scope.removeEvent = function(){
    $scope.errors.length = 0;
    $scope.messages.length = 0;
    swal({
        title: "Are you sure?",
        text: "Do you want to delete this event?",
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn-danger",
        confirmButtonText: "Yes I'm sure",
        closeOnConfirm: true,
        closeOnCancel:true
      },
      function(isConfirm){
        if(isConfirm){
          Agenda.removeItem($scope.event.id)
          .success(function(res){
            if(res.meta.success){
              $log.info("event deleted");
              $uibModalInstance.close({success:true});
            }
          })
          .error(function(error){
            $scope.errors.push(error);
          });
        }
      });
  };

  $scope.addAgency = function() {
    $log.info('addAgencty clicked');
    var modalInstance = $uibModal.open({
      animation: true,
      templateUrl: '/js/views/AddAgency.html',
      controller: 'AddAgencyController',
      size: 'lg',
      resolve: {
        data: data,
      }
    });
    modalInstance.result.then(function (result) {
      if(result && result.success){
        Agency.getAgencies().success(function(data){
          $scope.agencies = data.data;
        }).error(function(data){
          $log.error(data);
        });
      }
    }, function (err) {
      $log.error(err);
    });
  };

  $scope.addClient = function() {
    $log.info('addClient clicked');
    var modalInstance = $uibModal.open({
      animation: true,
      templateUrl: '/js/views/AddClient.html',
      controller: 'AddClientController',
      size: 'lg',
      resolve: {
        data: data,
      }
    });
    modalInstance.result.then(function (result) {
      if(result && result.success){
        Client.getClients().success(function(data){
          $scope.clients = data.data;
        }).error(function(data){
          $log.error(data);
        });
      }
    }, function (err) {
      $log.error(err);
    });
  };
});
