angular.module('vdmApp')
.controller('ProposalsCalendarController', function($scope, ProposalsAgenda, ProposalsStatus, $log, $compile, Client, Model, Agency, $uibModal, uiCalendarConfig) {
  var start = new Date();
  var end = new Date();

  var vm = this;
  $scope.selectedClients = [];
  $scope.clients = [];

  $scope.agencies = [];
  $scope.loading = false;

  $scope.clienttypes = [];
  $scope.availableClienttypes = [
    'Regular',
    'New',
  ];

  $scope.types = [];
  $scope.availableTypes = [
    'Proposal Request',
    'VDM Acquisition',
    'Test',
  ];

  $scope.statusses = [];
  $scope.availableStatusses = [];

  ProposalsStatus.getStatusses().success(function (res) {
    $scope.availableStatusses = res.data;
  });

  $scope.getStatusName = function(id) {
    var result = $scope.availableStatusses.find(x => x.id === id);
    return result.name;
  }

  /* Initialize Clients */
  Client.getClients().success(function(data){
    $scope.clients = data.data;
    // $scope.selectedClients = $scope.clients;
  }).error(function(data){
    $log.error(data);
  });

  /* Initialize agenda items */
  Agency.getAgencies().success(function(data){
    $scope.agencies = data.data;
  }).error(function(data){
    $log.error(data);
  });

  $scope.searchFiltered = function () {
    $scope.selectedClients = $scope.searchedClients;
    fetchProposals(start, end);
  };

  $scope.applyProposalItemFilters = function (items) {
    let result = items;

    if ($scope.types.length) {
      result = result.filter(a => {
        return !!$scope.types.find(t => t === a.type);
      });
    }

    if ($scope.clienttypes.length) {
      result = result.filter(a => {
        return !!$scope.clienttypes.find(ct => ct === a.clienttype);
      });
    }

    if ($scope.statusses.length) {
      result = result.filter(a => {
        return !!$scope.statusses.find(s => s === a.status_id);
      });
    }

    return result;
  };

  $scope.clearFilters = function() {
    $scope.statusses = [];
    $scope.types = [];
    $scope.clienttypes = [];
  }

  $scope.selectAll = function(){
    $scope.selectedClients = $scope.clients;
    fetchProposals(start, end);
  }

  $scope.deselectAll = function() {
    $scope.selectedClients = [];
    $scope.removeEvents();
  }

  $scope.toggleClient = function(client) {
    if($scope.isClientSelected(client)){
      var index = $scope.selectedClients.findIndex(x => x.id === client.id);
      $scope.selectedClients.splice(index, 1);
    } else {
      // Adding new Client, so add new EventSource
      $scope.selectedClients.push(client);
    }
    fetchProposals(start, end);
  }

  $scope.filterOnType = function (item) {
    $scope.types = $scope.types.find(x => x === item) 
    ? $scope.types.filter(x => x !== item) 
    : $scope.types.concat(item);

    fetchProposals(start, end);
  };

  $scope.filterOnStatus = function (item) {
    $scope.statusses = $scope.statusses.find(x => x === item) 
    ? $scope.statusses.filter(x => x !== item) 
    : $scope.statusses.concat(item);

    fetchProposals(start, end);
  };

  $scope.isStatusSelected = function (id) {
    return $scope.statusses.find(x => x === id);
  }

  $scope.filterOnClienttypes = function (item) {
    $scope.clienttypes = $scope.clienttypes.find(x => x === item) 
    ? $scope.clienttypes.filter(x => x !== item)
    : $scope.clienttypes.concat(item);

    fetchProposals(start, end);
  };

  $scope.isClientSelected = function (c) {
    return $scope.selectedClients.find(x => x.id === c.id);
  }

  /* alert on eventClick */
  $scope.onEventClick = function(data, jsEvent, view){
    var modalInstance = $uibModal.open({
      animation: true,
      templateUrl: '/js/views/ProposalsAgendaEditItem.html',
      controller: 'ProposalsAgendaEditItemCtrl',
      size: 'lg',
      resolve: {
        data: data,
        clients: function(){
          return $scope.clients;
        },
        agencies: function(){
          return $scope.agencies;
        },
        statusses: function() {
          return $scope.availableStatusses;
        }
      }
    });

    modalInstance.result.then(function (result) {
      if(result && result.success){
        fetchProposals(start, end);
        /* Initialize Clients */
        Client.getClients().success(function(data){
          $scope.clients = data.data;
        }).error(function(data){
          $log.error(data);
        });

        /* Initialize agenda items */
        Agency.getAgencies().success(function(data){
          $scope.agencies = data.data;
        }).error(function(data){
          $log.error(data);
        });}
      }, function () {

    });
  };

  /* alert on Drop */
  $scope.alertOnDrop = function(event, delta, revertFunc, jsEvent, ui, view){
    Agenda.updateItem(event)
    .success(function(res){
       fetchProposals(view.start, view.end);
    })
    .error(function(err){
      $log.error(err);
      revertFunc();
    });
  };
  /* alert on Resize */
  $scope.alertOnResize = function(event, delta, revertFunc, jsEvent, ui, view ){
    Agenda.updateItem(event)
    .success(function(res){
       fetchProposals(view.start, view.end);
    })
    .error(function(err){
      $log.error(err);
      revertFunc();
    });
  };

  $scope.onDayClick = function(date){
    $log.info("Day clicked : " + date);
    if(typeof date == 'undefined'){
      date = moment();
    }
    var modalInstance = $uibModal.open({
      animation: true,
      templateUrl: '/js/views/ProposalsAgendaAddItem.html',
      controller: 'ProposalsAgendaAddItemCtrl',
      size: 'lg',
      resolve: {
        data: date,
        clients: function(){
          return $scope.clients;
        },
        agencies: function(){
          return $scope.agencies;
        },
        statusses: function() {
          return $scope.availableStatusses;
        }
      }
    });

    modalInstance.result.then(function (result) {
      // Result true indicates a new Event was added and we can refetch
      if(result && result.success){
        fetchProposals(start, end);
      }
      Client.getClients().success(function(data){
        $scope.clients = data.data;
      }).error(function(data){
        $log.error(data);
      });
      /* Initialize agenda items */
      Agency.getAgencies().success(function(data){
        $scope.agencies = data.data;
      }).error(function(data){
        $log.error(data);
      });
    }, function () {
      // $log.info('Modal dismissed at: ' + new Date());
    });
  }

  /* add custom event*/
  $scope.addEvent = function(eventData) {
    /* start / end time * 1000 in Backend
    because javascript uses other timestamps */
    var classNames = [];
    classNames.push(eventData.status);
    if(eventData.type == 'Job'){
      classNames.push('boldEvent');
    }
    if(eventData.bookingtype){
      classNames.push(eventData.bookingtype);
    }

    eventData.title = eventData.name;
    eventData.className = classNames;
    eventData.start = moment(eventData.request_date).startOf('day').unix() * 1000;
    eventData.end = moment(eventData.request_date).endOf('day').unix() * 1000;
    return eventData;
  };

  $scope.removeEvents = function(){
    if(uiCalendarConfig.calendars['myCalendar']){
      uiCalendarConfig.calendars['myCalendar'].fullCalendar('removeEvents', function(event){
        return true;
      });
    }
  };

  var fetchProposals = function(start, end, timezone, callback){
    $scope.loading = true;

    //  start / 1000 because of javascript timestamp vs database
    ProposalsAgenda.getClientsItems($scope.selectedClients.map(x => x.id), start/1000, end/1000)
    .success(function(data){
      if(!data.meta.success){
        $scope.loading = false;
        return;
      }

      const filteredData = $scope.applyProposalItemFilters(data.data);
      var events = [];
 
      for (var i = 0; i < filteredData.length; i++) {
        events.push($scope.addEvent(filteredData[i]));
      }
      $scope.removeEvents();

      if(callback){
        $scope.removeEventSources();
        callback(events);
      } else {
        $scope.removeAndAddEvents(events);
      }
      $scope.loading = false;
    })
    .error(function(err){
      $log.error(err);
      $scope.loading = false;
    });
  }

  /**
   * FullCalendar specific
   */
  /* Change View */
  $scope.changeView = function(view,calendar) {
    uiCalendarConfig.calendars[calendar].fullCalendar('changeView',view);
  };
  $scope.removeEventSources = function() {
    if(uiCalendarConfig.calendars['myCalendar']){
      uiCalendarConfig.calendars['myCalendar'].fullCalendar('removeEventSources');
    }
  }

  /* add and removes an event source of choice */
  $scope.removeAndAddEvents = function(newEvents) {
    if(uiCalendarConfig.calendars['myCalendar']){
      uiCalendarConfig.calendars['myCalendar'].fullCalendar('removeEventSources');
      uiCalendarConfig.calendars['myCalendar'].fullCalendar('addEventSource', newEvents);
    }
  };

  $scope.rerenderEvents = function(){
    if(uiCalendarConfig.calendars['myCalendar']){
      uiCalendarConfig.calendars['myCalendar'].fullCalendar('rerenderEvents');
    }
  };

  /* Change View */
  $scope.renderCalender = function(calendar) {
    if(uiCalendarConfig.calendars[calendar]){
      uiCalendarConfig.calendars[calendar].fullCalendar('render');
    }
  };

  var getSelectedModelIds = function(){
    return $scope.selectedModels.map(function(model){
      return model.id
    });
  }

  $scope.uiConfig = {
    calendar: {
      displayEventTime: false,
      events: fetchProposals,
      height: "100%",
      editable: true,
      loading: function(isLoading, view){
        $scope.loading = isLoading;
      },
      header:{
        left: 'title',
        center: '',
        right: 'today prev,next'
      },
      dayClick: $scope.onDayClick,
      eventClick: $scope.onEventClick,
      eventDrop: $scope.alertOnDrop,
      eventResize: $scope.alertOnResize,
      defaultTimedEventDuration: '23:59:00',
      timezone: "local",
      timeFormat: ' ',
      ignoreTimezone: false,
      weekNumbers: true,
      firstDay: 1,
      weekNumberCalculation:"ISO",
      viewRender: function(view, element){
        start = view.start;
        end = view.end;
        fetchProposals(start, end);
      }
    }
  };
  /* event sources array*/
  $scope.eventSources = [];

});
