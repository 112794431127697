angular.module('vdmApp')
.controller('AgendaSearchController', function($scope, $filter, $log, $uibModal, Agenda, Client, Type, Model, Agency){
	$scope.loading = false;

  $scope.models = [];
  $scope.clients = [];
  $scope.agencies = [];
  $scope.types = [];
  $scope.isAdmin = user.email === 'jeroen@vdmmodels.com';

	$scope.period = {
    start: null,
    end: null,
	};

  $scope.isPeriodSelected = function () {
    return $scope.period.start && $scope.period.end;
  };

	$scope.statusses = [];
    $scope.availableStatusses = [
        'Pending',
        'Confirmed',
        'Confirmed by Client',
        'Confirmed by Model',
        'Cancelled by Client',
        'Cancelled by Model',
        'Cancelled',
        'Unknown',
    ];

	$scope.bookingtypes = [];
	$scope.availableBookingtypes = [
		'direct',
    'spain direct',
		'indirect',
		'women direct',
	];

	$scope.agendatypes = [];
	$scope.availableAgendatypes = [];

  // filters
  $scope.filters = {
    agency: null,
    client: null,
    model: null,
  };

  $scope.clearDate = function () {
    $scope.period.start = null;
    $scope.period.end = null;
    $scope.fetchData();
  };

  $scope.usePeriod = function () {
    $scope.period.start = moment().subtract('1', 'month');
    $scope.period.end = moment();
    $scope.fetchData();
  };

  $scope.removeFilter = function (filter) {
    $scope.filters[filter] = null;
    $scope.fetchData();
  };

	var table;
  $(document).ready(function() {
    table = $('.datatable').DataTable({
        processing: true,
        serverSide: true,
        ajax: generateAjaxUrl(),
        order: [[5, 'desc']],
        columnDefs: [
          {
            targets: [],
            className: 'mdl-data-table__cell--non-numeric'
          },
          {
            targets: [0, 11, 12, 13],
            visible: $scope.isAdmin,
          },
          {
            targets: [5, 6, 7],
            render: $.fn.dataTable.render.moment( 'X', 'DD MMM YY' )
          },
        ],
    });
    
    $('.datatable tbody').on('click', 'tr', function() {
      const data = table.row(this).data();
      // get item by id (first column, 0)
      Agenda.getItem(data[0])
      .then(function (res) {
        // fetchItem pass data
        $scope.onEventClick(res.data.data);
      });
    });
  });


	$scope.onEventClick = function(data){
		var modalInstance = $uibModal.open({
      templateUrl: '/js/views/AgendaEditItem.html',
      controller: 'AgendaEditItemCtrl',
      size: 'lg',
      resolve: {
        data: data,
        models: function(){
          return $scope.models;
        },
        clients: function(){
          return $scope.clients;
        },
        agencies: function(){
          return $scope.agencies;
        },
        types: function() {
          return $scope.types;
        }
      }
    });

		modalInstance.result.then(function (result) {
		  if(result && result.success){
		    $scope.fetchData();
		  }
		}, function () {

		});
	};

	$scope.filterOnBookingtype = function (item) {
	    $scope.bookingtypes = $scope.bookingtypes.find(x => x === item) 
	    ? $scope.bookingtypes.filter(x => x !== item) 
	    : $scope.bookingtypes.concat(item);
	    $scope.fetchData();
	};

	$scope.filterOnStatus = function (item) {
		$scope.statusses = $scope.statusses.find(x => x === item) 
		? $scope.statusses.filter(x => x !== item) 
		: $scope.statusses.concat(item);
		$scope.fetchData();
	};

	$scope.filterOnAgendatype = function (item) {
		$scope.agendatypes = $scope.agendatypes.find(x => x === item) 
		? $scope.agendatypes.filter(x => x !== item) 
		: $scope.agendatypes.concat(item);
		$scope.fetchData();
	};

  $scope.isTypeSelected = function (id) {
    return $scope.agendatypes.find(x => x.id === id);
  };

  $scope.getTypeById = function (id) {
    return $scope.types.find(x => x.id === id);
  };

	$scope.clearFilters = function () {
		$scope.bookingtypes = [];
		$scope.agendatypes = [];
		$scope.statusses = [];

		$scope.fetchData();
	};

	$scope.fetchData = function () {
		table.ajax.url(generateAjaxUrl()).load();
	}

	function generateAjaxUrl() {
		var url = '/api/agenda/search';

    if ($scope.period.start && $scope.period.end) {
      url += `${getQueryDelimiter(url)}period=${$scope.period.start.unix()},${$scope.period.end.endOf('day').unix()}`;
    }

    if ($scope.agendatypes.length) {
      url += `${getQueryDelimiter(url)}agendatypes=${$scope.agendatypes.join(',')}`;
    }

    if ($scope.bookingtypes.length) {
      url += `${getQueryDelimiter(url)}bookingtypes=${$scope.bookingtypes.join(',')}`;
    }

    if ($scope.statusses.length) {
      url += `${getQueryDelimiter(url)}statusses=${$scope.statusses.join(',')}`;
    }

    if ($scope.filters.agency) {
      url += `${getQueryDelimiter(url)}agency=${$scope.filters.agency}`;
    }

    if ($scope.filters.client) {
      url += `${getQueryDelimiter(url)}client=${$scope.filters.client}`;
    }

    if ($scope.filters.model) {
      url += `${getQueryDelimiter(url)}model=${$scope.filters.model}`;
    }

    return url;
	}

	function getQueryDelimiter(query) {
		return query.indexOf('?') > 0 ? '&' : '?';
	}

  Model.getModels().success(function(data){
    $scope.models = data.data;
  }).error(function(data){
    $log.error(data);
  });

  /* Initialize Clients */
  Client.getClients().success(function(data){
    $scope.clients = data.data;
  }).error(function(data){
    $log.error(data);
  });

  /* Initialize agenda items */
  Agency.getAgencies().success(function(data){
    $scope.agencies = data.data;
  }).error(function(data){
    $log.error(data);
  });

  /* Initialize types */
  Type.getTypes().success(function(data){
    $scope.types = data.data;
    $scope.availableAgendatypes = $scope.types.map(x => x.name);
  }).error(function(data){
    $log.error(data);
  });

});

$.fn.dataTable.render.moment = function ( from, to, locale ) {
    // Argument shifting
    if ( arguments.length === 1 ) {
        locale = 'en';
        to = from;
        from = 'YYYY-MM-DD';
    }
    else if ( arguments.length === 2 ) {
        locale = 'en';
    }
 
    return function ( d, type, row ) {
        var m = window.moment(d);
        // var m = window.moment( d, from, locale, true );
 
        // Order and type get a number value from Moment, everything else
        // sees the rendered value
        return m.format( type === 'sort' || type === 'type' ? 'x' : to );
    };
};
