angular.module('vdmApp')
.controller('AgendaitemEditInvoiceCtrl', function ($scope, $log, $timeout, $uibModalInstance, $uibModal, Invoice, data) {
    $scope.errors = [];
    $scope.messages = [];
    $scope.isEdit = true;
    $scope.lang = 'en';
    $scope.i18n = {};

    $scope.valutas = [
      {
        name: 'Pound',
        symbol: '&pound;',
      },
      {
        name: 'Dollar',
        symbol: '&dollar;',
      },
      {
        name: 'Euro',
        symbol: '&euro;',
      },
    ];

    $scope.getSymbol = function (valuta) {
      return $scope.valutas.find(x => x.name === valuta).symbol;
    };

    $scope.getLang = function(lang) {
      Invoice.getLang(lang).success(function (res) {
        $scope.i18n = res;
      });
    };

    $scope.getLang($scope.lang);

    $scope.addSendDate = function () {
      $scope.event.send_date = moment();
    };

    $scope.event = {
        id: data.id,
        agendaitem_id: data.agendaitem_id,
        invoice_nr: data.invoice_nr,
        client_name: data.client_name,
        send_date: data.send_date ? moment(data.send_date) : '',
        concerning: data.concerning,
        your_ref: data.your_ref,
        pdf: data.pdf,
        valuta: data.valuta,
        model_name: data.model_name,
        photographer: data.photographer,
        start: moment(data.start),
        end: moment(data.end),
        fee_desc: data.fee_desc,
        fee: parseFloat(data.fee),
        agency_fee_desc: data.agency_fee_desc,
        agency_fee: parseFloat(data.agency_fee),
        expenses_desc: data.expenses_desc,
        expenses: parseFloat(data.expenses),
        client_vat_id: data.client_vat_id,
        subtotal: parseFloat(data.subtotal),
        vat_desc: data.vat_desc,
        vat: parseFloat(data.vat),
        total: parseFloat(data.total),
        custom_field_1: data.custom_field_1,
        custom_value_1: data.custom_value_1,
        custom_field_price_1: parseFloat(data.custom_field_price_1),
        custom_field_2: data.custom_field_2,
        custom_value_2: data.custom_value_2,
        custom_field_price_2: parseFloat(data.custom_field_price_2),
        download_hash: data.download_hash,
    };

    $scope.getWorkdate = function () {
      const start = $scope.event.start.format('DD-MM-YYYY');
      const end = $scope.event.end.format('DD-MM-YYYY');
      if (start === end) return start;
      return `${start} ${$scope.i18n.to} ${end}`
    };

    $scope.changeLanguage = function () {
      $scope.lang = $scope.lang === 'en' ? 'nl' : 'en';
      // fetch new translations
      $scope.getLang($scope.lang);
    };

    $scope.ok = function () {
        $scope.errors = [];
        $scope.messages = [];

        Invoice.update($scope.event)
        .success(function(res){
          if(res.meta.success){
            $scope.messages.push(res.meta.message);
            // return Success to the Controller
            // indicating that we can refetch the events
            $uibModalInstance.close({success:true, data: $scope.event});
          } else {
            if(Object.keys(res.meta.message).length > 0){
              for (var i = res.meta.message.length - 1; i >= 0; i--) {
                $scope.errors.push(res.meta.message[i]);
              }
            } else {
              $scope.errors.push(res.meta.message);
            }
            $('.modal.fade.in').animate({scrollTop:0},0);
          }
        })
        .error(function(err){
          $log.debug('Error adding event', err);
          if(err.errors) {
            for(var key in err.errors) {
              if(err.errors.hasOwnProperty(key)){
                $scope.errors.push(err.errors[key]);
                $('.modal.fade.in').animate({scrollTop:0},0);
              }
            }
          }
        });
    };

    $scope.calcTotals = function () {
      const fee = $scope.event.fee ? $scope.event.fee : 0;
      const agency_fee = $scope.event.agency_fee ? $scope.event.agency_fee : 0;
      const expenses = $scope.event.expenses ? $scope.event.expenses : 0;
      const vat = $scope.event.vat ? $scope.event.vat : 0;
      const custom1 = $scope.event.custom_field_price_1 ? $scope.event.custom_field_price_1 : 0;
      const custom2 = $scope.event.custom_field_price_2 ? $scope.event.custom_field_price_2 : 0;

      $scope.event.subtotal = parseFloat((fee + agency_fee + expenses + custom1 + custom2).toFixed(2));
      $scope.event.total = parseFloat(($scope.event.subtotal + vat).toFixed(2));
    };

    $scope.downloadUrl = function () {
      return `/api/invoicepdf/${$scope.event.id}/?lang=${$scope.lang}`;
    };

    $scope.cancel = function () {
        if($scope.event && Object.keys($scope.event).length > 0){
            swal({
                title: "Are you sure?",
                text: "You have some unsaved changes!",
                type: "info",
                showCancelButton: true,
                confirmButtonClass: "btn-danger",
                confirmButtonText: "Yes",
                closeOnConfirm: true,
                closeOnCancel:true
            },
            function(isConfirm){
                if(isConfirm){
                    $uibModalInstance.dismiss('cancel');
                }
            });
        } else {
            $uibModalInstance.dismiss('cancel');
        }
    };
})
