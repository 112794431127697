angular.module('vdmApp')
.controller('AddAgencyController', function ($scope, $log, $timeout, $uibModalInstance, data, Agency) {

  $scope.errors = [];
  $scope.agency = {};

  $scope.cancel = function() {
    $log.info('cancel');
    $uibModalInstance.dismiss('cancel');
  };

  $scope.save = function() {
    $scope.errors = [];
    $log.info('saving: ' + JSON.stringify($scope.agency));
    Agency.store($scope.agency).success(function(res) {
      $uibModalInstance.close({
        agency: res.data,
        success: true
      });
    }).error(function(err) {
      if(err.errors) {
        for(var key in err.errors) {
          if(err.errors.hasOwnProperty(key)){
            $scope.errors.push(err.errors[key]);
          }
        }
      }
    });
  };
});
