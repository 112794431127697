angular.module('vdmApp')
.controller('ProposalsSearchController', function($scope, $filter, $log, $uibModal, ProposalsAgenda, Client, ProposalsStatus, Agency){
  $scope.loading = false;

  $scope.clients = [];
  $scope.agencies = [];

  $scope.period = {
    start: null,
    end: null,
  };

  $scope.isPeriodSelected = function () {
    return $scope.period.start && $scope.period.end;
  };

  $scope.clearDate = function () {
    $scope.period.start = null;
    $scope.period.end = null;
    $scope.fetchData();
  };

  $scope.usePeriod = function () {
    $scope.period.start = moment().subtract('1', 'month');
    $scope.period.end = moment();
    $scope.fetchData();
  };

  $scope.statusses = [];
  $scope.availableStatusses = [];

  ProposalsStatus.getStatusses().success(function (res) {
    $scope.availableStatusses = res.data;
  });

  $scope.clienttypes = [];
  $scope.availableClienttypes = [
    'Regular',
    'New',
  ];

  $scope.types = [];
  $scope.availableTypes = [
    'Proposal Request',
    'VDM Acquisition',
    'Test',
  ];

  // filters
  $scope.filters = {
    client: null,
  };

  $scope.removeFilter = function (filter) {
    $scope.filters[filter] = null;
    $scope.fetchData();
  };

  var table;
  $(document).ready(function() {
    table = $('.datatable').DataTable({
        processing: true,
        serverSide: true,
        ajax: generateAjaxUrl(),
        order: [[4, 'desc']],
        columnDefs: [
          {
              targets: [],
              className: 'mdl-data-table__cell--non-numeric'
          },
          {
            targets: [0],
            visible: false,
          },
          {
            targets: [4],
            render: $.fn.dataTable.render.moment( 'X', 'DD MMM YY' )
          },
        ]
    });
    
    $('.datatable tbody').on('click', 'tr', function() {
      const data = table.row(this).data();
      // get item by id (first column, 0)
      ProposalsAgenda.get(data[0])
      .then(function (res) {
        // fetchItem pass data
        $scope.onEventClick(res.data.data);
      });
    });
  });


  $scope.onEventClick = function(data){
    var modalInstance = $uibModal.open({
      templateUrl: '/js/views/ProposalsAgendaEditItem.html',
      controller: 'ProposalsAgendaEditItemCtrl',
      size: 'lg',
      resolve: {
        data: data,
        clients: function(){
          return $scope.clients;
        },
        agencies: function(){
          return $scope.agencies;
        },
        statusses: function() {
          return $scope.availableStatusses;
        }
      }
    });

    modalInstance.result.then(function (result) {
      if(result && result.success){
        $scope.fetchData();
      }
    }, function () {

    });
  };

  $scope.filterOnClienttype = function (item) {
      $scope.clienttypes = $scope.clienttypes.find(x => x === item) 
      ? $scope.clienttypes.filter(x => x !== item) 
      : $scope.clienttypes.concat(item);
      $scope.fetchData();
  };

  $scope.filterOnType = function (item) {
    $scope.types = $scope.types.find(x => x === item) 
    ? $scope.types.filter(x => x !== item) 
    : $scope.types.concat(item);
    $scope.fetchData();
  };

  $scope.getStatusName = function(id) {
    var result = $scope.availableStatusses.find(x => x.id === id);
    return result.name;
  }

  $scope.isStatusSelected = function (id) {
    return $scope.statusses.find(x => x === id);
  }

  $scope.filterOnStatus = function (id) {
    $scope.statusses = $scope.statusses.find(x => x === id) 
    ? $scope.statusses.filter(x => x !== id) 
    : $scope.statusses.concat(id);
    $scope.fetchData();
  };

  $scope.clearFilters = function () {
    $scope.clienttypes = [];
    $scope.types = [];
    $scope.statusses = [];

    $scope.fetchData();
  };

  $scope.fetchData = function () {
    table.ajax.url(generateAjaxUrl()).load();
  }

  function generateAjaxUrl() {
    var url = '/api/proposalssearch';

    if ($scope.period.start && $scope.period.end) {
      url += `${getQueryDelimiter(url)}period=${$scope.period.start.unix()},${$scope.period.end.endOf('day').unix()}`;
    }

    if ($scope.clienttypes.length) {
      url += `${getQueryDelimiter(url)}clienttypes=${$scope.clienttypes.join(',')}`;
    }

    if ($scope.types.length) {
      url += `${getQueryDelimiter(url)}types=${$scope.types.join(',')}`;
    }

    if ($scope.statusses.length) {
      url += `${getQueryDelimiter(url)}statusses=${$scope.statusses.join(',')}`;
    }

    if ($scope.filters.client) {
      url += `${getQueryDelimiter(url)}client=${$scope.filters.client}`;
    }

    return url;
  }

  function getQueryDelimiter(query) {
    return query.indexOf('?') > 0 ? '&' : '?';
  }

  /* Initialize Clients */
  Client.getClients().success(function(data){
    $scope.clients = data.data;
  }).error(function(data){
    $log.error(data);
  });

  /* Initialize agenda items */
  Agency.getAgencies().success(function(data){
    $scope.agencies = data.data;
  }).error(function(data){
    $log.error(data);
  });
});

$.fn.dataTable.render.moment = function ( from, to, locale ) {
    // Argument shifting
    if ( arguments.length === 1 ) {
        locale = 'en';
        to = from;
        from = 'YYYY-MM-DD';
    }
    else if ( arguments.length === 2 ) {
        locale = 'en';
    }
 
    return function ( d, type, row ) {
        var m = window.moment(d);
        // var m = window.moment( d, from, locale, true );
 
        // Order and type get a number value from Moment, everything else
        // sees the rendered value
        return m.format( type === 'sort' || type === 'type' ? 'x' : to );
    };
};
