angular.module('photobookApp', ['ng-sortable','vdmApp'])
.config(['$compileProvider', function ($compileProvider) {
  $compileProvider.debugInfoEnabled(true);
}])
.controller('photobookController', function($scope){

  $scope.modelData = window.models;
  $scope.selectedModel = {};
  $scope.photobookData = window.photobookData || [];

  $scope.selectModel = function(){
    if(!$scope.selectedId){
      return $scope.selectedModel = {};
    }
    for(key in this.modelData){
        if(!this.modelData.hasOwnProperty(key)) continue;

        if(this.modelData[key].id == this.selectedId){
          if(!this.photobookData.find((m) => m.modelId == this.selectedId)) {
            $scope.photobookData.push({
              modelId: this.modelData[key].id,
              name: this.modelData[key].name,
              photos: [],
            })
          }
          $scope.selectedModel = this.modelData[key];
        }
      }
  }

  $scope.existsInPhotobook = function(photoId){
    for (var i = 0; i < this.photobookData.length; i++) {
      for (var j = 0; j < this.photobookData[i].photos.length; j++) {
        if(this.photobookData[i].photos[j].id == photoId) {
          return true;
        }
      }
    }
    return false;
  };

  $scope.getAddedPhotos = function(){
    var photos = [];
    for (var i = 0; i < this.photobookData.length; i++) {
      if(!this.photobookData[i].photos){
        continue; // skip if it doesn't have photos
      }
      for (var j = 0; j < this.photobookData[i].photos.length; j++) {
        photos.push(this.photobookData[i].photos[j].id);
      }
    }
    return photos;
  };

  $scope.addPhoto = function(data){
    // Only push if not in array
    for (var i = 0; i < this.photobookData.length; i++) {
      if(this.photobookData[i].modelId == this.selectedId) {
        if(!this.photobookData[i].photos.find(p => p.id == data.id)) {
          $scope.photobookData[i]['photos'].push(data);
        }
      }
    }
  }

  $scope.removePhoto = function(data){
    for (var i = 0; i < this.photobookData.length; i++) {
      for (var j = 0; j < this.photobookData[i]['photos'].length; j++) {
        if(this.photobookData[i]['photos'][j].id == data.id) {
          $scope.photobookData[i]['photos'].splice(j,1);
          break;
        }
      }
    }
  };

  $scope.moveUp = function(index) {
    console.log(this.photobookData[index])
    if(index == 0) {
      console.log('already at index 0');
      return;
    }
    this.move($scope.photobookData, index, index-1);
  }

  $scope.moveDown = function(index) {
    console.log(this.photobookData[index])
    if(index == this.photobookData.length - 1) {
      console.log('already last index', this.photobookData.length-1);
      return;
    }
    this.move($scope.photobookData, index, index+1);
  };

  $scope.move = function(array,fromIndex,toIndex){
    array.splice(toIndex, 0, array.splice(fromIndex, 1)[0] );
  };

});
