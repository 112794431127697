angular.module('vdmApp')
.controller('AgendaitemAddChecklistCtrl', function ($scope, $log, $timeout, $uibModalInstance, $uibModal, Checklist, data) {
    $scope.errors = [];
    $scope.messages = [];

 
    $scope.event = {
        id: null,
        agendaitem_id: data.id,
        fee: null,
        workdays: null,
        buyout: null,
        buyout_description: '',
        inclusive_af: '',
        inclusive_af_description: '',
        fee_model: null,
        flights: null,
        flights_description: '',
        stayings: null,
        stayings_description: '',
        overtime: null,
        overtime_description: '',
        travel_expenses: null,
        travel_expenses_description: '',
        callsheet_po: null,
        callsheet_po_description: '',
        other: null,
        other_description: '',
        test_shoot: null,
        test_shoot_description: '',
        model_statement: null,
        model_statement_description: '',
        model_commision_percent: '',
        model_commision_payment: '',        
        agency_fee_percent: '',
        agency_fee_payment: '',
        foreign_model: null,
        foreign_model_description: '',        
        mac_commision: null,
        mac_commision_description: '',
        vat_client: '',   
    };

    $scope.ok = function () {
        $scope.errors = [];
        $scope.messages = [];

        Checklist.add($scope.event)
        .success(function(res){
          if(res.meta.success){
            $scope.messages.push(res.meta.message);
            // return Success to the Controller
            // indicating that we can refetch the events
            $scope.event.id = res.data.id;
            $uibModalInstance.close({success:true, data: $scope.event});
          } else {
            if(Object.keys(res.meta.message).length > 0){
              for (var i = res.meta.message.length - 1; i >= 0; i--) {
                $scope.errors.push(res.meta.message[i]);
              }
            } else {
              $scope.errors.push(res.meta.message);
            }
            $('.modal.fade.in').animate({scrollTop:0},0);
          }
        })
        .error(function(err){
          $log.debug('Error adding event', err);
          if(err.errors) {
            for(var key in err.errors) {
              if(err.errors.hasOwnProperty(key)){
                $scope.errors.push(err.errors[key]);
                $('.modal.fade.in').animate({scrollTop:0},0);
              }
            }
          }
        });
    };

    $scope.print = function() {
    };

    $scope.cancel = function () {
        if($scope.event && Object.keys($scope.event).length > 0){
            swal({
                title: "Are you sure?",
                text: "You have some unsaved changes!",
                type: "info",
                showCancelButton: true,
                confirmButtonClass: "btn-danger",
                confirmButtonText: "Yes",
                closeOnConfirm: true,
                closeOnCancel:true
            },
            function(isConfirm){
                if(isConfirm){
                    $uibModalInstance.dismiss('cancel');
                }
            });
        } else {
            $uibModalInstance.dismiss('cancel');
        }
    };
});
