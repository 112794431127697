angular.module('vdmApp')
.controller('EmailController', function ($scope, $log, $timeout, $uibModalInstance) {

  $scope.email = '';

  $scope.cancel = function() {
    $log.info('cancel');
    $uibModalInstance.dismiss('cancel');
  };

  $scope.sendEmail = function() {
    $scope.errors = [];
    $log.info('saving: ' + JSON.stringify($scope.email));
    $uibModalInstance.close({
      email: $scope.email,
      success: true
    });
  };

});
