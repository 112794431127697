angular.module('vdmApp')
.controller('AddClientController', function ($scope, $log, $timeout, $uibModalInstance, data, Group, Client, Country) {

  $scope.groups = [];
  $scope.countries = [];

  Group.getGroups().success(function(res) {
    $scope.groups = res.data;
  }).error(function(err) {
    $log.error(err);
  });
  Country.getCountries().success(function(res) {
    $scope.countries = res.data;
  }).error(function(err) {
    $log.error(err);
  });

  $scope.client = {};

  $scope.cancel = function() {
    $log.info('cancel');
    $uibModalInstance.dismiss('cancel');
  };

  $scope.save = function() {
    $scope.errors = [];
    $log.info('saving: ' + JSON.stringify($scope.client));
    Client.store($scope.client).success(function(res) {
      $uibModalInstance.close({
        client: res.data,
        success: true
      });
    }).error(function(err) {
      if(err.errors) {
        for(var key in err.errors) {
          if(err.errors.hasOwnProperty(key)){
            $scope.errors.push(err.errors[key]);
          }
        }
      }
    });
  };
});
